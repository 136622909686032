var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("div", { staticClass: "m-2" }, [
            _c("div", { staticClass: "form-row justify-content-between" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.name,
                      expression: "filters.name",
                    },
                  ],
                  staticClass: "form-control d-inline-block",
                  attrs: { type: "search", placeholder: "Nome" },
                  domProps: { value: _vm.filters.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "name", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.email,
                      expression: "filters.email",
                    },
                  ],
                  staticClass: "form-control d-inline-block",
                  attrs: { type: "email", placeholder: "E-mail" },
                  domProps: { value: _vm.filters.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "email", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.cpf,
                      expression: "filters.cpf",
                    },
                  ],
                  staticClass: "form-control d-inline-block",
                  attrs: { type: "tel", placeholder: "CPF" },
                  domProps: { value: _vm.filters.cpf },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "cpf", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "col-md-2" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.cellphone,
                      expression: "filters.cellphone",
                    },
                  ],
                  staticClass: "form-control d-inline-block",
                  attrs: { type: "tel", placeholder: "Celular" },
                  domProps: { value: _vm.filters.cellphone },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "cellphone", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "col-md-1" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-block",
                    staticStyle: { padding: "14px !important" },
                    attrs: { type: "button" },
                    on: { click: _vm.search },
                  },
                  [
                    !_vm.btnSearch
                      ? _c("i", { staticClass: "bi bi-search" })
                      : _c("b-spinner", {
                          attrs: { small: "", variant: "light" },
                        }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              items: _vm.dataItems,
              fields: _vm.tableColumns,
              "show-empty": "",
              striped: "",
              hover: "",
              busy: _vm.loading,
              "empty-html":
                "Clique no ícone <strong><i class='bi bi-search'></i></strong> para filtrar os clientes.",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(full_name)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-content": "center",
                          "justify-content": "flex-start",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            data.item.origin_name
                              ? _c(
                                  "b-badge",
                                  {
                                    staticClass: "mr -1 py-75",
                                    attrs: { variant: "light-dark" },
                                  },
                                  [_c("i", { class: data.item.origin_icon })]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "pl-1" }, [
                          _c("span", { staticClass: "d-block" }, [
                            _vm._v(_vm._s(data.item.full_name)),
                          ]),
                          _c("span", [_vm._v(_vm._s(data.item.email))]),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (data) {
                  return [
                    data.item.created_at
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("datePT")(data.item.created_at, true)
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-between" },
                      [
                        _vm.$can("Editar", "Clientes")
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "customers-edit",
                                    params: { uuid: data.item.uuid },
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "bi bi-pencil-square cursor-pointer cursor",
                                  attrs: { icon: "EditIcon" },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "b-dropdown",
                          {
                            staticClass: "dropdown-actions",
                            attrs: {
                              variant: "link",
                              "toggle-class": "p-0",
                              "no-caret": "",
                              right: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c("feather-icon", {
                                        staticClass: "align-middle text-body",
                                        attrs: {
                                          icon: "MoreVerticalIcon",
                                          size: "16",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm.$can("Gerenciar Carrinho", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.btnCustomerAddCartAction(
                                          data.item.uuid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-cart2 mr-50",
                                    }),
                                    _c("span", [_vm._v("Gerenciar Carrinho")]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$can("Cortesia - EAD", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.btnCustomerAddProductAction(
                                          data.item.uuid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-box2-heart mr-50",
                                    }),
                                    _c("span", [_vm._v("Cortesia - EAD")]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$can("Cortesia - Pedagógico", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.btnCustomerAddGridAction(
                                          data.item.uuid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-calendar3 mr-50",
                                    }),
                                    _c("span", [
                                      _vm._v("Cortesia - Pedagógico"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$can("Matricular", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "customers-enrollment-create",
                                          params: { uuid: data.item.uuid },
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-headset mr-50",
                                    }),
                                    _c("span", [_vm._v("Matricular")]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$can("Matricular por disciplina", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push({
                                          name: "customers-enrollment-disciplines",
                                          params: { uuid: data.item.uuid },
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-headset mr-50",
                                    }),
                                    _c("span", [
                                      _vm._v("Matrícula por disciplina"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$can("Matrícula - EAD", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.btnCustomerAddProductEADAction(
                                          data.item.uuid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-wallet2 mr-50",
                                    }),
                                    _c("span", [
                                      _vm._v("Venda - EAD e Apostila"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$can("Acessar Conta", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.btnCustomerLoginAction(
                                          data.item.uuid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi bi-box-arrow-in-right mr-50",
                                    }),
                                    _c("span", [_vm._v("Acessar Conta")]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$can("Excluir", "Clientes")
                              ? _c("hr")
                              : _vm._e(),
                            _vm.$can("Excluir", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    staticClass: "text-danger",
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmDelete(data.item.uuid)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi bi-trash mr-50 text-danger",
                                    }),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v("Excluir"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-primary my-2" },
                      [
                        _c("b-spinner", {
                          staticClass: "align-middle",
                          staticStyle: { "margin-right": "3px" },
                          attrs: { small: "" },
                        }),
                        _c("strong", [_vm._v(" carregando...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.filters.currentPage,
              "per-page": _vm.filters.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
      _c("CustomerModalCreate"),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modalImport",
            "modal-class": "modal-import",
            title: "Importar clientes",
            centered: "",
            "hide-footer": "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitImport.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(" Selecione o arquivo "),
                    _c("i", { staticClass: "text-danger bi bi-record-circle" }),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      ref: "file",
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.$v.toImport.file.$error },
                      staticStyle: { padding: "0.4rem 0.5rem" },
                      attrs: {
                        type: "file",
                        id: "file",
                        accept:
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleFileUpload()
                        },
                      },
                    }),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-alert",
                      {
                        staticClass: "mb-0",
                        attrs: { variant: "dark", show: "", "d-block": "" },
                      },
                      [
                        _c("h4", { staticClass: "alert-heading" }, [
                          _vm._v("Atenção"),
                        ]),
                        _c("div", { staticClass: "alert-body" }, [
                          _vm._v(
                            " Se atente as regras para migração de cientes e seu histórco de compras. "
                          ),
                          _c("p", { staticClass: "mb-2" }, [
                            _c("strong", [_vm._v("Formato do arquivo:")]),
                            _vm._v(" XLSX "),
                            _c("br"),
                            _c("strong", [_vm._v("Colunas:")]),
                            _vm._v(" nome, cpf, email, id_curso "),
                            _c(
                              "span",
                              { staticClass: "text-danger d-block mb-1" },
                              [_vm._v(" (Todos as colunas são obrigatórias) ")]
                            ),
                            _c("span", { staticClass: "text-info" }, [
                              _vm._v(
                                "Senha do aluno: últimos 6 dígitos do CPF."
                              ),
                            ]),
                          ]),
                          _c("p", { staticClass: "mb-2" }, [
                            _vm._v(
                              ' A forma de pagamento que será exibida no relatório será "Migração". Caso o CPF já exista, somente o produto será adicionado na conta do cliente. '
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "form-row justify-content-end modal-footer-custom",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-7 col-12" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light float-left",
                          attrs: { disabled: _vm.submitted, type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.hide("modalImport")
                            },
                          },
                        },
                        [_vm._v(" Fechar ")]
                      ),
                      _c("ButtonsActionsFooter", {
                        attrs: {
                          variant: "success",
                          submited: _vm.submitted,
                          text: "Enviar",
                          subtext: "Enviando...",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "add-courtesy",
            "modal-class": "modal-customer-add-product",
            title: "Adicionar cortesia",
            centered: "",
            "hide-footer": "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitNewOrder.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v(" Produto ")]),
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            "item-text": "title",
                            "item-value": "code",
                            placeholder: "Digite o título",
                            options: _vm.optionsProducts,
                          },
                          on: {
                            search: _vm.fetchProducts,
                            input: _vm.productSelected,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(option.title),
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.product,
                            callback: function ($$v) {
                              _vm.product = $$v
                            },
                            expression: "product",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Origem da liberação "),
                      ]),
                      _c(
                        "v-select",
                        {
                          class: {
                            "is-invalid": _vm.$v.formCourtesy.origin.$error,
                          },
                          attrs: {
                            label: "title",
                            "item-text": "title",
                            "item-value": "code",
                            searchable: false,
                            placeholder: "Digite o título",
                            options: _vm.optionsOrigins,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(option.title),
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.formCourtesy.origin,
                            callback: function ($$v) {
                              _vm.$set(_vm.formCourtesy, "origin", $$v)
                            },
                            expression: "formCourtesy.origin",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [
                      _vm._v("Dias de acesso "),
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.top",
                            value:
                              "Se não for preenchido, será usado o tempo de validade cadastrado no produto.",
                            expression:
                              "'Se não for preenchido, será usado o tempo de validade cadastrado no produto.'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "bi bi-question-circle",
                      }),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["###"],
                          expression: "['###']",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formCourtesy.validity,
                          expression: "formCourtesy.validity",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "tel" },
                      domProps: { value: _vm.formCourtesy.validity },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formCourtesy,
                            "validity",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-12" }, [_c("hr")]),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Motivo "),
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formCourtesy.note,
                          expression: "formCourtesy.note",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.$v.formCourtesy.note.$error },
                      attrs: { maxlength: "250", cols: "30", rows: "2" },
                      domProps: { value: _vm.formCourtesy.note },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formCourtesy,
                            "note",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-12",
                    staticStyle: { "min-height": "250px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Produtos selecionados "),
                        ]),
                        _vm.formCourtesy.products.length
                          ? _c(
                              "draggable",
                              {
                                staticClass: "list-group mt-1",
                                attrs: {
                                  tag: "ul",
                                  list: _vm.formCourtesy.products,
                                  handle: ".handle",
                                },
                              },
                              _vm._l(
                                _vm.formCourtesy.products,
                                function (element, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: element.code,
                                      staticClass: "list-group-item",
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "text-not-selected",
                                        domProps: {
                                          innerHTML: _vm._s(element.title),
                                        },
                                      }),
                                      _c("feather-icon", {
                                        staticClass: "float-right text-danger",
                                        attrs: {
                                          icon: "XCircleIcon",
                                          size: "20",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeProduct(
                                              index,
                                              false
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        !_vm.formCourtesy.products.length ||
                        _vm.$v.formCourtesy.products.$error
                          ? _c("div", { staticClass: "alert alert-dark" }, [
                              _c("div", { staticClass: "alert-body" }, [
                                _c("p", [
                                  _vm._v(
                                    "Nenhum produto foi selecionado até o momento."
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "form-row justify-content-end modal-footer-custom",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-7 col-12" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light float-left",
                          attrs: { disabled: _vm.submitted, type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.hide("add-courtesy")
                            },
                          },
                        },
                        [_vm._v(" Fechar ")]
                      ),
                      _c("ButtonsActionsFooter", {
                        attrs: {
                          variant: "success",
                          submited: _vm.submitted,
                          text: "Salvar",
                          subtext: "Enviando...",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-enrollment-ead",
            "modal-class": "modal-customer-add-product",
            title: "Venda - EAD e Apostila",
            centered: "",
            "hide-footer": "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitNewOrderEAD.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("label", [
                    _c("i", { staticClass: "bi bi-search" }),
                    _vm._v(" Selecione 1 ou mais produtos "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            "item-text": "title",
                            "item-value": "code",
                            placeholder: "Digite o título do produto",
                            options: _vm.optionsProducts,
                          },
                          on: {
                            search: _vm.fetchProductsSale,
                            input: _vm.productEADSelected,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(option.title),
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.product,
                            callback: function ($$v) {
                              _vm.product = $$v
                            },
                            expression: "product",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-12" }, [_c("hr")]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Origem "),
                      ]),
                      _c(
                        "v-select",
                        {
                          class: {
                            "is-invalid":
                              _vm.$v.formEnrollmentEAD.origin.$error,
                          },
                          attrs: {
                            label: "title",
                            "item-text": "title",
                            "item-value": "code",
                            searchable: false,
                            placeholder: "Digite o título",
                            options: _vm.optionsOrigins,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(option.title),
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.formEnrollmentEAD.origin,
                            callback: function ($$v) {
                              _vm.$set(_vm.formEnrollmentEAD, "origin", $$v)
                            },
                            expression: "formEnrollmentEAD.origin",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Forma de pagamento "),
                      ]),
                      _c("v-select", {
                        class: {
                          "is-invalid": _vm.$v.formEnrollmentEAD.method.$error,
                        },
                        attrs: {
                          searchable: false,
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          options: _vm.optionsPaymentMethodsEAD,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (ref) {
                              var code = ref.code
                              var title = ref.title
                              return [
                                _c("i", {
                                  staticClass: "bi mr-75",
                                  class: _vm.getIconPaymentMethod(code),
                                }),
                                _c("span", [_vm._v(" " + _vm._s(title))]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.formEnrollmentEAD.method,
                          callback: function ($$v) {
                            _vm.$set(_vm.formEnrollmentEAD, "method", $$v)
                          },
                          expression: "formEnrollmentEAD.method",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm.formEnrollmentEAD.method &&
                (_vm.formEnrollmentEAD.method.title === "Cartão de Crédito" ||
                  _vm.formEnrollmentEAD.method.title === "Cartão de Débito") &&
                _vm.formEnrollmentEAD.origin.title !== "À distância" &&
                _vm.formEnrollmentEAD.method.title !== "Carta de Crédito"
                  ? _c("div", { staticClass: "col-md-6" }, [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Bandeira"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group mb-md-0" },
                        [
                          _c("v-select", {
                            class: {
                              "is-invalid":
                                _vm.$v.formEnrollmentEAD.brand.$error,
                            },
                            attrs: {
                              searchable: false,
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              options: _vm.optionsCardNetworks,
                              disabled: !_vm.formEnrollmentEAD.method,
                            },
                            model: {
                              value: _vm.formEnrollmentEAD.brand,
                              callback: function ($$v) {
                                _vm.$set(_vm.formEnrollmentEAD, "brand", $$v)
                              },
                              expression: "formEnrollmentEAD.brand",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("label", [
                    _vm._v(" Cód. Venda "),
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.top",
                          value:
                            "Código gerado ao efetuar o pagamento na maquininha.",
                          expression:
                            "'Código gerado ao efetuar o pagamento na maquininha.'\n              ",
                          modifiers: { hover: true, top: true },
                        },
                      ],
                      staticClass: "bi bi-question-circle",
                    }),
                  ]),
                  _c("div", { staticClass: "form-group mb-md-0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formEnrollmentEAD.transaction,
                          expression: "formEnrollmentEAD.transaction",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.formEnrollmentEAD.transaction },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formEnrollmentEAD,
                            "transaction",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-12" }, [_c("hr")]),
                _c(
                  "div",
                  { staticClass: "col-md-5" },
                  [
                    _c(
                      "b-button",
                      {
                        staticStyle: { "text-transform": "uppercase" },
                        attrs: {
                          disabled:
                            !_vm.formEnrollmentEAD.method ||
                            !_vm.formEnrollmentEAD.products.length ||
                            (_vm.formEnrollmentEAD.method.title ===
                              "Cartão de Crédito" &&
                              !_vm.formEnrollmentEAD.installment),
                          block: "",
                          variant: "primary",
                        },
                        on: { click: _vm.getDiscount },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex",
                            staticStyle: { "column-gap": "3px" },
                          },
                          [
                            _c("span", { staticClass: "text-left" }, [
                              _vm._v("Solicitar desconto"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-5" },
                  [
                    _c(
                      "b-button",
                      {
                        staticStyle: { "text-transform": "uppercase" },
                        attrs: {
                          disabled:
                            !_vm.formEnrollmentEAD.method ||
                            !_vm.formEnrollmentEAD.products.length ||
                            (_vm.formEnrollmentEAD.method.title ===
                              "Cartão de Crédito" &&
                              !_vm.formEnrollmentEAD.installment),
                          block: "",
                          variant: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$bvModal.show("modal-discount-apply")
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex",
                            staticStyle: { "column-gap": "3px" },
                          },
                          [
                            _c("span", { staticClass: "text-left" }, [
                              _vm._v("Aplicar desconto"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-light",
                      attrs: { block: "", type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.show("modal-discounts-categories")
                        },
                      },
                    },
                    [_c("i", { staticClass: "bi bi-info-circle" })]
                  ),
                ]),
                _c("div", { staticClass: "col-md-12" }, [
                  _vm.manager.status === 0 && _vm.manager.sent
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-primary p-1 mt-1",
                          staticStyle: {
                            "font-weight": "400",
                            "font-size": "1.1rem",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "bi bi-hourglass-split",
                            staticStyle: { "margin-right": "5px" },
                          }),
                          _vm._v(
                            " A solicitação foi enviada via WhatsApp para os gestores. "
                          ),
                          _c("strong", [
                            _c("u", [_vm._v("NÃO ATUALIZE A PÁGINA!")]),
                          ]),
                          _vm._v(". "),
                        ]
                      )
                    : _vm._e(),
                  _vm.manager.status === 1
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-success p-1 mt-1",
                          staticStyle: {
                            "font-weight": "400",
                            "font-size": "1.1rem",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "bi bi-check-circle",
                            staticStyle: { "margin-right": "5px" },
                          }),
                          _vm._v(" Sua solicitação de "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.formEnrollmentEAD.discount.percent) +
                                "% "
                            ),
                          ]),
                          _vm._v(" de desconto foi "),
                          _c("strong", { staticClass: "text-success" }, [
                            _vm._v("APROVADA"),
                          ]),
                          _vm._v("! "),
                        ]
                      )
                    : _vm._e(),
                  _vm.manager.status === 2
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-danger p-1 mt-1",
                          staticStyle: {
                            "font-weight": "400",
                            "font-size": "1.1rem",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "bi bi-x-circle",
                            staticStyle: { "margin-right": "5px" },
                          }),
                          _vm._v(" A sua solictação de desconto foi "),
                          _c("strong", { staticClass: "text-danger" }, [
                            _vm._v("REPROVADA"),
                          ]),
                          _vm._v("! "),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm.formEnrollmentEAD.method.title === "Cartão de Crédito"
                  ? _c("div", { staticClass: "col-12" }, [_c("hr")])
                  : _vm._e(),
                _vm.formEnrollmentEAD.method.title === "Cartão de Crédito"
                  ? _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Parcelamento "),
                          ]),
                          _c(
                            "v-select",
                            {
                              class: {
                                "is-invalid":
                                  _vm.$v.formEnrollmentEAD.installment.$error,
                              },
                              attrs: {
                                label: "title",
                                searchable: false,
                                "item-text": "title",
                                "item-value": "code",
                                options: _vm.optionsInstallments,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "option",
                                    fn: function (option) {
                                      return [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(option.title),
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4146209711
                              ),
                              model: {
                                value: _vm.formEnrollmentEAD.installment,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formEnrollmentEAD,
                                    "installment",
                                    $$v
                                  )
                                },
                                expression: "formEnrollmentEAD.installment",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-12" }, [_c("hr")]),
                _c(
                  "div",
                  {
                    staticClass: "col-12",
                    staticStyle: { "min-height": "250px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Produtos selecionados "),
                        ]),
                        _vm.formEnrollmentEAD.products.length
                          ? _c(
                              "draggable",
                              {
                                staticClass: "list-group mt-1",
                                attrs: {
                                  tag: "ul",
                                  list: _vm.formEnrollmentEAD.products,
                                  handle: ".handle",
                                },
                              },
                              _vm._l(
                                _vm.formEnrollmentEAD.products,
                                function (element, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: element.code,
                                      staticClass: "list-group-item",
                                    },
                                    [
                                      _c("div", { staticClass: "form-row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-8 text-left" },
                                          [
                                            _c("span", {
                                              staticClass: "text-not-selected",
                                              staticStyle: {
                                                "font-size": "0.9rem",
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  element.title
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-3 text-center",
                                            staticStyle: {
                                              "font-size": "0.9rem",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    element.value
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-md-1 text-center",
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass:
                                                "float-right text-danger",
                                              attrs: {
                                                icon: "XCircleIcon",
                                                size: "20",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeProduct(
                                                    index,
                                                    true
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        !_vm.formEnrollmentEAD.products.length ||
                        _vm.$v.formEnrollmentEAD.products.$error
                          ? _c("div", { staticClass: "alert alert-dark" }, [
                              _c("div", { staticClass: "alert-body" }, [
                                _c("p", [
                                  _vm._v(
                                    "Nenhum produto foi selecionado até o momento."
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "d-flex",
                            staticStyle: {
                              "column-gap": "6px",
                              "align-items": "center",
                              "justify-content": "space-between",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "Subtotal: " +
                                  _vm._s(
                                    _vm.toCurrency(
                                      _vm.formEnrollmentEAD.subtotal
                                    )
                                  )
                              ),
                            ]),
                            _c("strong", { staticClass: "text-success" }, [
                              _vm._v(
                                "Desconto: " +
                                  _vm._s(
                                    _vm.formEnrollmentEAD.discount.percent
                                  ) +
                                  "%"
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                "Total: " +
                                  _vm._s(
                                    _vm.toCurrency(_vm.formEnrollmentEAD.total)
                                  )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "form-row justify-content-end modal-footer-custom",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-7 col-12" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light float-left",
                          attrs: { disabled: _vm.submitted, type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.hide("modal-enrollment-ead")
                            },
                          },
                        },
                        [_vm._v(" Fechar ")]
                      ),
                      _c("ButtonsActionsFooter", {
                        attrs: {
                          variant: "success",
                          submited: _vm.submitted,
                          text: "Salvar",
                          subtext: "Enviando...",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "add-grid",
            "modal-class": "modal-customer-add-classroom",
            title: "Adicionar Turma",
            centered: "",
            "hide-footer": "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitNewGrid.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Produto"),
                      ]),
                      _c(
                        "v-select",
                        {
                          class: { "is-invalid": _vm.$v.grid.product.$error },
                          attrs: {
                            label: "title",
                            "item-text": "title",
                            "item-value": "code",
                            placeholder: "Digite o título",
                            options: _vm.optionsProductsGrid,
                          },
                          on: {
                            search: _vm.fetchProductsGrid,
                            input: _vm.productsGridSelected,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(option.title),
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.grid.product,
                            callback: function ($$v) {
                              _vm.$set(_vm.grid, "product", $$v)
                            },
                            expression: "grid.product",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-8" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Turma"),
                      ]),
                      _c(
                        "v-select",
                        {
                          class: { "is-invalid": _vm.$v.grid.classroom.$error },
                          attrs: {
                            disabled: !_vm.grid.product,
                            label: "title",
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsClassrooms,
                          },
                          on: { input: _vm.classroomSelected },
                          scopedSlots: _vm._u([
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(option.title),
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.grid.classroom,
                            callback: function ($$v) {
                              _vm.$set(_vm.grid, "classroom", $$v)
                            },
                            expression: "grid.classroom",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Acesso até"),
                      ]),
                      _c("flat-pickr", {
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.$v.grid.date_end.$error },
                        attrs: {
                          config: {
                            altInput: true,
                            dateFormat: "Y-m-d",
                            altFormat: "d/m/Y",
                            locale: "pt",
                          },
                        },
                        model: {
                          value: _vm.grid.date_end,
                          callback: function ($$v) {
                            _vm.$set(_vm.grid, "date_end", $$v)
                          },
                          expression: "grid.date_end",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-12" }, [_c("hr")]),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Motivo "),
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.grid.note,
                          expression: "grid.note",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { maxlength: "250", cols: "30", rows: "2" },
                      domProps: { value: _vm.grid.note },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.grid, "note", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "form-row justify-content-end modal-footer-custom",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-7 col-12" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light float-left",
                          attrs: { disabled: _vm.submitted, type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.hide("add-grid")
                            },
                          },
                        },
                        [_vm._v(" Fechar ")]
                      ),
                      _c("ButtonsActionsFooter", {
                        attrs: {
                          variant: "success",
                          submited: _vm.submitted,
                          text: "Salvar",
                          subtext: "Enviando...",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-discount-request",
            "modal-class": "modal-customer-add-product",
            title: "Solicitar desconto",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitGetDiscount.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-row align-items-center" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("p", { staticClass: "text-success mb-0" }, [
                    _vm._v(
                      " O desconto será aplicado após aprovação do gestor. "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Quantos % deseja conceder? "),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formRequestDiscount.percent,
                          expression: "formRequestDiscount.percent",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.$v.formRequestDiscount.percent.$error,
                      },
                      attrs: {
                        type: "number",
                        max: "100",
                        placeholder: "%",
                        disabled: _vm.manager.loading,
                        pattern: "\\d*",
                      },
                      domProps: { value: _vm.formRequestDiscount.percent },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formRequestDiscount,
                              "percent",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            return _vm.maxRequestDiscount(
                              _vm.formRequestDiscount.percent
                            )
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Motivo do desconto "),
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formRequestDiscount.note,
                          expression: "formRequestDiscount.note",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.$v.formRequestDiscount.note.$error,
                      },
                      domProps: { value: _vm.formRequestDiscount.note },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formRequestDiscount,
                            "note",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-row justify-content-end" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light btn-block",
                      staticStyle: { "line-height": "1.3" },
                      attrs: { type: "button" },
                      on: { click: _vm.closeModalDiscountRequest },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                ]),
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success btn-block",
                      staticStyle: { "line-height": "1.3" },
                      attrs: { type: "submit" },
                    },
                    [_vm._v(" Solicitar ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-discount-apply",
            "modal-class": "modal-customer-add-product",
            title: "Conceder desconto",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
          },
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitGiveDiscount.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-row align-items-center" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("p", { staticClass: "text-success mb-0" }, [
                    _vm._v(" Você tem permissão para conceder até "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.userDiscountEAD.max) + "%"),
                    ]),
                    _vm._v(" de desconto. "),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Quantos % deseja conceder? "),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formGiveDiscount.percent,
                          expression: "formGiveDiscount.percent",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.$v.formGiveDiscount.percent.$error,
                      },
                      attrs: {
                        type: "number",
                        max: _vm.userDiscountEAD.max,
                        placeholder: "%",
                        pattern: "\\d*",
                      },
                      domProps: { value: _vm.formGiveDiscount.percent },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formGiveDiscount,
                              "percent",
                              $event.target.value
                            )
                          },
                          _vm.maxDiscount,
                        ],
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Motivo do desconto "),
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formGiveDiscount.note,
                          expression: "formGiveDiscount.note",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.$v.formGiveDiscount.note.$error,
                      },
                      domProps: { value: _vm.formGiveDiscount.note },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formGiveDiscount,
                            "note",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-row justify-content-end" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-light btn-block",
                      staticStyle: { "line-height": "1.3" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.closeModalDiscountOfUser()
                        },
                      },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                ]),
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success btn-block",
                      staticStyle: { "line-height": "1.3" },
                      attrs: { type: "submit" },
                    },
                    [_vm._v(" Conceder desconto ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            id: "modal-discounts-categories",
            title: "Permissão para descontos",
            "hide-footer": "",
          },
        },
        [
          _c(
            "b-card-text",
            [
              _c("p", [
                _vm._v(
                  " Veja abaixo o percentual de desconto que você poderá conceder para cada categoria de produto. "
                ),
              ]),
              _c(
                "b-list-group",
                { staticClass: "list-group-messages" },
                _vm._l(_vm.userDiscounts, function (element, index) {
                  return _c("b-list-group-item", { key: index }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-8 text-left col-8" }, [
                        _vm._v(" " + _vm._s(element.name) + " "),
                      ]),
                      _c("div", { staticClass: "col-md-4 text-center col-4" }, [
                        _vm._v(" Até " + _vm._s(element.max) + "% "),
                      ]),
                    ]),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }