<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="form-row justify-content-between">
          <div class="col-md-3">
            <input type="search" class="form-control d-inline-block" placeholder="Nome" v-model="filters.name" />
          </div>

          <div class="col-md-3">
            <input type="email" class="form-control d-inline-block" placeholder="E-mail" v-model="filters.email" />
          </div>

          <div class="col-md-3">
            <input type="tel" class="form-control d-inline-block" placeholder="CPF" v-model="filters.cpf" />
          </div>

          <div class="col-md-2">
            <input type="tel" class="form-control d-inline-block" placeholder="Celular" v-model="filters.cellphone" />
          </div>

          <div class="col-md-1">
            <button @click="search" style="padding: 14px !important" type="button" class="btn btn-primary btn-block">
              <i v-if="!btnSearch" class="bi bi-search"></i>
              <b-spinner v-else small variant="light" />
            </button>
          </div>

        </div>
      </div>

      <b-table class="position-relative" :items="dataItems" :fields="tableColumns" show-empty striped hover
        :busy="loading" empty-html="Clique no ícone <strong><i class='bi bi-search'></i></strong> para filtrar os clientes.">
        <template #cell(full_name)="data">
          <div style="
              display: flex;
              align-content: center;
              justify-content: flex-start;
              align-items: center;
            ">
            <div>
              <b-badge class="mr -1 py-75" v-if="data.item.origin_name" variant="light-dark">
                <i :class="data.item.origin_icon"></i>
              </b-badge>
            </div>

            <div class="pl-1">
              <span class="d-block">{{ data.item.full_name }}</span>
              <span>{{ data.item.email }}</span>
            </div>
          </div>
        </template>
        <template #cell(created_at)="data">
          <span v-if="data.item.created_at">
            {{ data.item.created_at | datePT(true) }}
          </span>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex-between">
            <router-link v-if="$can('Editar', 'Clientes')" :to="{ name: 'customers-edit', params: { uuid: data.item.uuid }, }">
              <i icon="EditIcon" class="bi bi-pencil-square cursor-pointer cursor"></i>
            </router-link>              

            <b-dropdown variant="link" toggle-class="p-0" no-caret right class="dropdown-actions">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item v-if="$can('Gerenciar Carrinho', 'Clientes')"
                @click="btnCustomerAddCartAction(data.item.uuid)">
                <i class="bi bi-cart2 mr-50"></i>
                <span>Gerenciar Carrinho</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="$can('Cortesia - EAD', 'Clientes')"
                @click="btnCustomerAddProductAction(data.item.uuid)">
                <i class="bi bi-box2-heart mr-50"></i>
                <span>Cortesia - EAD</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="$can('Cortesia - Pedagógico', 'Clientes')"
                @click="btnCustomerAddGridAction(data.item.uuid)">
                <i class="bi bi-calendar3 mr-50"></i>
                <span>Cortesia - Pedagógico</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="$can('Matricular', 'Clientes')" @click="$router.push({ name: 'customers-enrollment-create', params: { uuid: data.item.uuid }})">
                <i class="bi bi-headset mr-50"></i>
                <span>Matricular</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="$can('Matricular por disciplina', 'Clientes')" @click="$router.push({ name: 'customers-enrollment-disciplines', params: { uuid: data.item.uuid }})">
                <i class="bi bi-headset mr-50"></i>
                <span>Matrícula por disciplina</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="$can('Matrícula - EAD', 'Clientes')"
                @click="btnCustomerAddProductEADAction(data.item.uuid)">
                <i class="bi bi-wallet2 mr-50"></i>
                <span>Venda - EAD e Apostila</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="$can('Acessar Conta', 'Clientes')" @click="btnCustomerLoginAction(data.item.uuid)">
                <i class="bi bi-box-arrow-in-right mr-50"></i>
                <span>Acessar Conta</span>
              </b-dropdown-item>

              <hr v-if="$can('Excluir', 'Clientes')" />

              <b-dropdown-item @click="confirmDelete(data.item.uuid)" v-if="$can('Excluir', 'Clientes')"
                class="text-danger">
                <i class="bi bi-trash mr-50 text-danger"></i>
                <span class="text-danger">Excluir</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner small class="align-middle" style="margin-right: 3px"></b-spinner>
            <strong> carregando...</strong>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable :rows-table="rowsTable" :current-page="filters.currentPage" :per-page="filters.perPage"
        :total-rows="totalRows" />
    </b-card>

    <CustomerModalCreate />

    <b-modal id="modalImport" modal-class="modal-import" title="Importar clientes" centered hide-footer hide-header-close
      no-close-on-backdrop>
      <b-form @submit.prevent="submitImport">
        <div class="form-row">
          <div class="col-md-12">
            <label for="">
              Selecione o arquivo
              <i class="text-danger bi bi-record-circle"></i>
            </label>
            <div class="form-group">
              <input style="padding: 0.4rem 0.5rem" type="file" id="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ref="file" class="form-control"
                :class="{ 'is-invalid': $v.toImport.file.$error }" @change="handleFileUpload()" />
            </div>
          </div>
          <div class="col-md-12">
            <b-alert variant="dark" show d-block class="mb-0">
              <h4 class="alert-heading">Atenção</h4>
              <div class="alert-body">
                Se atente as regras para migração de cientes e seu histórco de
                compras.
                <p class="mb-2">
                  <strong>Formato do arquivo:</strong> XLSX <br />
                  <strong>Colunas:</strong> nome, cpf, email, id_curso
                  <span class="text-danger d-block mb-1">
                    (Todos as colunas são obrigatórias)
                  </span>

                  <span class="text-info">Senha do aluno: últimos 6 dígitos do CPF.</span>
                </p>

                <p class="mb-2">
                  A forma de pagamento que será exibida no relatório será
                  "Migração". Caso o CPF já exista, somente o produto será
                  adicionado na conta do cliente.
                </p>
              </div>
            </b-alert>
          </div>
        </div>
        <div class="form-row justify-content-end modal-footer-custom">
          <div class="col-md-7 col-12">
            <button :disabled="submitted" type="button" @click="$bvModal.hide('modalImport')"
              class="btn btn-light float-left">
              Fechar
            </button>
            <ButtonsActionsFooter variant="success" :submited="submitted" text="Enviar" subtext="Enviando..." />
          </div>
        </div>
      </b-form>
    </b-modal>

    <b-modal id="add-courtesy" modal-class="modal-customer-add-product" title="Adicionar cortesia" centered hide-footer
      hide-header-close no-close-on-backdrop>
      <b-form @submit.prevent="submitNewOrder">
        <div class="form-row">
          <div class="col-md-12">
            <div class="form-group">
              <label>
                Produto
              </label>
              <v-select label="title" item-text="title" item-value="code" v-model="product" placeholder="Digite o título"
                :options="optionsProducts" @search="fetchProducts" @input="productSelected">
                <template v-slot:option="option">
                  <span v-html="option.title"></span>
                </template>
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Origem da liberação
              </label>
              <v-select label="title" item-text="title" item-value="code" :searchable="false"
                v-model="formCourtesy.origin" placeholder="Digite o título" :options="optionsOrigins"
                :class="{ 'is-invalid': $v.formCourtesy.origin.$error }">
                <template v-slot:option="option">
                  <span v-html="option.title"></span>
                </template>
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label>Dias de acesso  <i class="bi bi-question-circle" v-b-tooltip.hover.top="'Se não for preenchido, será usado o tempo de validade cadastrado no produto.'"></i></label>
              <input v-mask="['###']" type="tel" v-model="formCourtesy.validity" class="form-control" />
            </div>
          </div>

          <div class="col-12">
            <hr />
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Motivo
              </label>
              <textarea maxlength="250" class="form-control" v-model="formCourtesy.note"
                :class="{ 'is-invalid': $v.formCourtesy.note.$error }" cols="30" rows="2"></textarea>
            </div>
          </div>
          <div class="col-12" style="min-height: 250px">
            <div class="form-group">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Produtos selecionados
              </label>
              <draggable tag="ul" :list="formCourtesy.products" class="list-group mt-1" handle=".handle"
                v-if="formCourtesy.products.length">
                <li v-for="(element, index) in formCourtesy.products" :key="element.code" class="list-group-item">
                  <span class="text-not-selected" v-html="element.title"></span>

                  <feather-icon icon="XCircleIcon" class="float-right text-danger" size="20" @click="removeProduct(index, false)" />
                </li>
              </draggable>

              <div v-if="!formCourtesy.products.length || $v.formCourtesy.products.$error" class="alert alert-dark">
                <div class="alert-body">
                  <p>Nenhum produto foi selecionado até o momento.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row justify-content-end modal-footer-custom">
          <div class="col-md-7 col-12">
            <button :disabled="submitted" type="button" @click="$bvModal.hide('add-courtesy')"
              class="btn btn-light float-left">
              Fechar
            </button>
            <ButtonsActionsFooter variant="success" :submited="submitted" text="Salvar" subtext="Enviando..." />
          </div>
        </div>
      </b-form>
    </b-modal>

    <b-modal id="modal-enrollment-ead" modal-class="modal-customer-add-product" title="Venda - EAD e Apostila" centered hide-footer
      hide-header-close no-close-on-backdrop>
      <b-form @submit.prevent="submitNewOrderEAD">
        <div class="form-row">
          <div class="col-md-12">
            <label>
              <i class="bi bi-search"></i>
              Selecione 1 ou mais produtos
            </label>
            <div class="form-group">
              <v-select label="title" item-text="title" item-value="code" v-model="product" placeholder="Digite o título do produto"
                :options="optionsProducts" @search="fetchProductsSale" @input="productEADSelected">
                <template v-slot:option="option">
                  <span v-html="option.title"></span>
                </template>
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-12">
            <hr />
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Origem
              </label>
              <v-select label="title" item-text="title" item-value="code" :searchable="false"
                v-model="formEnrollmentEAD.origin" placeholder="Digite o título" :options="optionsOrigins"
                :class="{ 'is-invalid': $v.formEnrollmentEAD.origin.$error }">
                <template v-slot:option="option">
                  <span v-html="option.title"></span>
                </template>
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Forma de pagamento
              </label>
              <v-select :searchable="false" label="title" :class="{ 'is-invalid': $v.formEnrollmentEAD.method.$error }" item-text="title" v-model="formEnrollmentEAD.method"
                item-value="code" :options="optionsPaymentMethodsEAD">
                <template #option="{ code, title }">
                  <i class="bi mr-75" :class="getIconPaymentMethod(code)"></i>
                  <span> {{ title }}</span>
                </template>
              </v-select>
            </div>
          </div>

          <div class="col-md-6" v-if="formEnrollmentEAD.method && (formEnrollmentEAD.method.title === 'Cartão de Crédito' ||
            formEnrollmentEAD.method.title === 'Cartão de Débito') && formEnrollmentEAD.origin.title !== 'À distância' && formEnrollmentEAD.method.title !== 'Carta de Crédito'">
            <label><i class="text-danger bi bi-record-circle"></i> Bandeira</label>
            <div class="form-group mb-md-0">
              <v-select :searchable="false" label="title" item-text="title" v-model="formEnrollmentEAD.brand"
                item-value="code" :options="optionsCardNetworks" :class="{ 'is-invalid': $v.formEnrollmentEAD.brand.$error }"
                :disabled="!formEnrollmentEAD.method" />
            </div>
          </div>

          <div class="col-md-6">
            <label>
              Cód. Venda
              <i class="bi bi-question-circle" v-b-tooltip.hover.top="'Código gerado ao efetuar o pagamento na maquininha.'
                "></i>
            </label>
            <div class="form-group mb-md-0">
              <input type="text" class="form-control" v-model="formEnrollmentEAD.transaction" />
            </div>
          </div>

          <div class="col-12">
            <hr />
          </div>

          <div class="col-md-5">
            <b-button :disabled="!formEnrollmentEAD.method || !formEnrollmentEAD.products.length || (formEnrollmentEAD.method.title === 'Cartão de Crédito' && !formEnrollmentEAD.installment)" style="text-transform: uppercase;" block @click="getDiscount" variant="primary">
              <div class="d-flex" style="column-gap: 3px;">
                <span class="text-left">Solicitar desconto</span>
              </div>
            </b-button>
          </div>

          <div class="col-md-5">
            <b-button :disabled="!formEnrollmentEAD.method || !formEnrollmentEAD.products.length || (formEnrollmentEAD.method.title === 'Cartão de Crédito' && !formEnrollmentEAD.installment)" style="text-transform: uppercase;" block @click="$bvModal.show('modal-discount-apply')" variant="primary">
              <div class="d-flex" style="column-gap: 3px;">
                <span class="text-left">Aplicar desconto</span>
              </div>
            </b-button>
          </div>

          <div class="col-md-2">
            <button block type="button" @click="$bvModal.show('modal-discounts-categories')" class="btn btn-block btn-light">
              <i class="bi bi-info-circle"></i>
            </button>
          </div>

          <div class="col-md-12">
            <div style="font-weight: 400; font-size: 1.1rem;" class="alert alert-primary p-1 mt-1" v-if="manager.status === 0 && manager.sent">
              <i class="bi bi-hourglass-split" style="margin-right: 5px;"></i> A solicitação foi enviada via WhatsApp para os gestores. <strong><u>NÃO ATUALIZE A PÁGINA!</u></strong>.
            </div>

            <div style="font-weight: 400; font-size: 1.1rem;" class="alert alert-success p-1 mt-1" v-if="manager.status === 1">
              <i class="bi bi-check-circle" style="margin-right: 5px;"></i> Sua solicitação de <strong>{{ formEnrollmentEAD.discount.percent }}% </strong> de desconto foi <strong class="text-success">APROVADA</strong>!
            </div>

            <div style="font-weight: 400; font-size: 1.1rem;" class="alert alert-danger p-1 mt-1" v-if="manager.status === 2">
              <i class="bi bi-x-circle" style="margin-right: 5px;"></i> A sua solictação de desconto foi <strong class="text-danger">REPROVADA</strong>!
            </div>
          </div>         

          <div class="col-12" v-if="formEnrollmentEAD.method.title === 'Cartão de Crédito'">
            <hr />
          </div>
          
          <div class="col-12" v-if="formEnrollmentEAD.method.title === 'Cartão de Crédito'">
            <div class="form-group">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Parcelamento
              </label>
              <v-select label="title" :searchable="false" item-text="title" item-value="code" v-model="formEnrollmentEAD.installment" :options="optionsInstallments" :class="{ 'is-invalid': $v.formEnrollmentEAD.installment.$error }">
                <template v-slot:option="option">
                  <span v-html="option.title"></span>
                </template>
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          
          <div class="col-12">
            <hr />
          </div>
         
          <div class="col-12" style="min-height: 250px">
            <div class="form-group">
              <label>
                <i class="text-danger bi bi-record-circle"></i>
                Produtos selecionados
              </label>
              <draggable tag="ul" :list="formEnrollmentEAD.products" class="list-group mt-1" handle=".handle"
                v-if="formEnrollmentEAD.products.length">
                <li v-for="(element, index) in formEnrollmentEAD.products" :key="element.code" class="list-group-item">
                  <div class="form-row">
                    <div class="col-md-8 text-left">
                      <span class="text-not-selected" style="font-size: 0.9rem;" v-html="element.title"></span>
                    </div>
                    <div class="col-md-3 text-center" style="font-size: 0.9rem;">
                      {{ element.value | toCurrency }}
                    </div>
                    <div class="col-md-1 text-center">
                      <feather-icon icon="XCircleIcon" class="float-right text-danger" size="20" @click="removeProduct(index, true)" />
                    </div>
                  </div>
                </li>
              </draggable>

              <div v-if="!formEnrollmentEAD.products.length || $v.formEnrollmentEAD.products.$error" class="alert alert-dark">
                <div class="alert-body">
                  <p>Nenhum produto foi selecionado até o momento.</p>
                </div>
              </div>

              <div class="d-flex" style="column-gap: 6px; align-items: center; justify-content: space-between; margin-top: 10px;">
                <div>Subtotal: {{ toCurrency(formEnrollmentEAD.subtotal) }}</div>
                <strong class="text-success">Desconto: {{ formEnrollmentEAD.discount.percent }}%</strong>
                <div>Total: {{ toCurrency(formEnrollmentEAD.total) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row justify-content-end modal-footer-custom">
          <div class="col-md-7 col-12">
            <button :disabled="submitted" type="button" @click="$bvModal.hide('modal-enrollment-ead')"
              class="btn btn-light float-left">
              Fechar
            </button>
            <ButtonsActionsFooter variant="success" :submited="submitted" text="Salvar" subtext="Enviando..." />
          </div>
        </div>
      </b-form>
    </b-modal>

    <b-modal id="add-grid" modal-class="modal-customer-add-classroom" title="Adicionar Turma" centered hide-footer
      hide-header-close no-close-on-backdrop>
      <b-form @submit.prevent="submitNewGrid">
        <div class="form-row">
          <div class="col-12">
            <div class="form-group">
              <label><i class="text-danger bi bi-record-circle"></i> Produto</label>
              <v-select label="title" item-text="title" item-value="code" v-model="grid.product"
                placeholder="Digite o título" :options="optionsProductsGrid" @search="fetchProductsGrid"
                @input="productsGridSelected" :class="{ 'is-invalid': $v.grid.product.$error }">
                <template v-slot:option="option">
                  <span v-html="option.title"></span>
                </template>
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-8">
            <div class="form-group">
              <label><i class="text-danger bi bi-record-circle"></i> Turma</label>
              <v-select :disabled="!grid.product" label="title" item-text="title" v-model="grid.classroom"
                item-value="code" :options="optionsClassrooms" @input="classroomSelected" :class="{ 'is-invalid': $v.grid.classroom.$error }">
                <template v-slot:option="option">
                  <span v-html="option.title"></span>
                </template>
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><i class="text-danger bi bi-record-circle"></i> Acesso até</label>
              <flat-pickr v-model="grid.date_end" class="form-control"
                :class="{ 'is-invalid': $v.grid.date_end.$error }"
                :config="{
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>

          <div class="col-12">
            <hr />
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Motivo
              </label>
              <textarea maxlength="250" class="form-control" v-model="grid.note" cols="30" rows="2"></textarea>
            </div>
          </div>
        </div>
        <div class="form-row justify-content-end modal-footer-custom">
          <div class="col-md-7 col-12">
            <button :disabled="submitted" type="button" @click="$bvModal.hide('add-grid')"
              class="btn btn-light float-left">
              Fechar
            </button>
            <ButtonsActionsFooter variant="success" :submited="submitted" text="Salvar" subtext="Enviando..." />
          </div>
        </div>
      </b-form>
    </b-modal>

    <b-modal id="modal-discount-request" modal-class="modal-customer-add-product" title="Solicitar desconto" centered
      hide-footer no-close-on-backdrop>
      <form @submit.prevent="submitGetDiscount">
        <div class="form-row align-items-center">
          <div class="col-md-6">
            <p class="text-success mb-0">
              O desconto será aplicado após aprovação do gestor.
            </p>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Quantos % deseja conceder?
              </label>
              <input type="number" @input="maxRequestDiscount(formRequestDiscount.percent)" max="100" class="form-control" :class="{ 'is-invalid': $v.formRequestDiscount.percent.$error }" placeholder="%" v-model="formRequestDiscount.percent" :disabled="manager.loading"
                pattern="\d*" />
            </div>
          </div> 

          <div class="col-md-12">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Motivo do desconto
              </label>
              <textarea  :class="{ 'is-invalid': $v.formRequestDiscount.note.$error }" v-model="formRequestDiscount.note" class="form-control"></textarea>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-end">
          <div class="col-md-3">
            <button @click="closeModalDiscountRequest" type="button" class="btn btn-light btn-block"
              style="line-height: 1.3">
              Cancelar
            </button>
          </div>
          <div class="col-md-4">
            <button type="submit" class="btn btn-success btn-block"
              style="line-height: 1.3">
              Solicitar
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal id="modal-discount-apply" modal-class="modal-customer-add-product" title="Conceder desconto" centered
      hide-footer no-close-on-backdrop>
      <form @submit.prevent="submitGiveDiscount">
        <div class="form-row align-items-center">
          <div class="col-md-6">
            <p class="text-success mb-0">
              Você tem permissão para conceder até
              <strong>{{ userDiscountEAD.max }}%</strong> de desconto.
            </p>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Quantos % deseja conceder?
              </label>
              <input type="number" :class="{ 'is-invalid': $v.formGiveDiscount.percent.$error }"  @input="maxDiscount" :max="userDiscountEAD.max" class="form-control" placeholder="%" v-model="formGiveDiscount.percent"
                pattern="\d*" />
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Motivo do desconto
              </label>
              <textarea v-model="formGiveDiscount.note" :class="{ 'is-invalid': $v.formGiveDiscount.note.$error }" class="form-control"></textarea>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-end">
          <div class="col-md-3">
            <button @click="closeModalDiscountOfUser()" type="button" class="btn btn-light btn-block"
              style="line-height: 1.3">
              Cancelar
            </button>
          </div>
          <div class="col-md-4">
            <button type="submit" class="btn btn-success btn-block"
              style="line-height: 1.3">
              Conceder desconto
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal centered id="modal-discounts-categories" title="Permissão para descontos" hide-footer>
      <b-card-text>
        <p>
          Veja abaixo o percentual de desconto que você poderá conceder para cada categoria de produto.
        </p>
        <b-list-group class="list-group-messages">
          <b-list-group-item v-for="(element, index) in userDiscounts" :key="index">
            <div class="row">
              <div class="col-md-8 text-left col-8">
                {{ element.name }}
              </div>
              <div class="col-md-4 text-center col-4"> Até {{ element.max }}% </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { Money } from "v-money";
import {
  BCard,
  BListGroup,
  BCardText,
  BListGroupItem,
  BDropdownItem,
  BAlert,
  BTooltip,
  VBTooltip,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  BSidebar,
  BForm,
  BFormGroup,
  BFormRow,
  BSpinner,
  BModal,
  BFormCheckbox,
  BDropdown,
  BDropdownDivider,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { required, minLength, minValue, requiredIf } from "vuelidate/lib/validators";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import CustomerModalCreate from '@/views/customer/customer/components/Modal/Create'

import io from 'socket.io-client';

export default {
  components: {
    BAlert,
    BDropdownDivider,
    BDropdownItem,
    BDropdown,
    ButtonsActionsFooter,
    CustomPaginateTable,
    BSidebar,
    flatPickr,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BTooltip,
    BFormInput,
    BSpinner,
    Money,
    BCard,
    BModal,
    BRow,
    BForm,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    CustomerModalCreate,
    BBadge,
    BPagination,
    BFormRow,
    vSelect,
    BFormCheckbox,
    draggable,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  validations: {
    toImport: {
      file: {
        required,
      },
    },
    formGiveDiscount: {
      percent: {
        required,
      },
      note: {
        required,
        minLength: minLength(5)
      },
    },
    formRequestDiscount: {
      percent: {
        required,
      },
      note: {
        required,
        minLength: minLength(5)
      },
    },
    grid: {
      note: {
        required,
        minLength: minLength(5),
      },
      product: {
        required,
      },
      date_end: {
        required,
      },
      classroom: {
        required,
      },
    },
    order: {
      note: {
        required,
        minLength: minLength(5),
      },
      products: {
        required,
      },
    },
    formCourtesy: {
      note: {
        required,
        minLength: minLength(5),
      },
      origin: {
        required,
      },
      products: {
        required,
      },
    },
    formEnrollmentEAD: {
      customer: {
        required,
      },
      origin: {
        required,
      },
      products: {
        required,
      },
      method: {
        required,
      },
      brand: {
        required: requiredIf((vm) => {
          return vm.method && vm.method.title === 'Cartão de Crédito'
            ? true
            : false;
        }),
      },
      installment: {
        required: requiredIf((vm) => {
          return vm.method && vm.method.title === 'Cartão de Crédito'
            ? true
            : false;
        }),
      },
    },
  },
  data: () => ({
    customerUuid: "",
    isBtnCustomerAddProductActive: false,
    isBtnCustomerAddCartActive: false,
    isBtnCustomerAddGridActive: false,
    file: "",
    submitted: false,
    filters: {
      name: "",
      cpf: "",
      email: "",
      cellphone: "",
      currentPage: 1,
      perPage: 100,
    },
    loading: false,
    isAddNewUserSidebarActive: false,
    totalRows: 0,
    rowsTable: 0,
    dataItems: [],
    toImport: {
      file: "",
      send_mail: "",
    },
    tableColumns: [
      {
        key: "full_name",
        label: "Nome completo",
        thStyle: "width: 400px",
        class: "text-elipse-400",
      },
      {
        key: "cpf",
        label: "CPF",
        class: "text-center",
      },
      {
        key: "created_at",
        label: "Cadastrado",
        class: "text-center",
      },
      {
        key: "actions",
        label: "",
        class: "text-center",
        thStyle: "width: 80px",
      },
    ],
    optionsTags: [],
    btnSearch: false,
    grid: {
      note: "",
      product: "",
      date_end: "",
      classroom: "",
    },
    optionsProducts: [],
    optionsProductsGrid: [],
    optionsClassrooms: [],
    optionsOrigins: [],
    optionsCardNetworks: [],
    optionsPaymentMethods: [],
    optionsPaymentMethodsEAD: [],
    optionsInstallments: [],
    product: "",
    order: {
      note: "",
      products: [],
    },
    user: "",
    formCourtesy: {
      products: [],
      origin: '',
      validity: '',
      note: '',
    },
    formEnrollmentEAD: {
      customer: null,
      products: [],
      origin: '',
      note: '',
      method: '',
      brand: '',
      subtotal: 0,
      total: 0,
      transaction: '',
      discount: {
        percent: 0,
        total: 0,
        note: 0,
      },
      installment: null
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: false,
    },
    formGiveDiscount: {
      percent: 0,
      note: '',
    },
    formRequestDiscount: {
      percent: 0,
      note: '',
    },
    userDiscountEAD: 0,
    userDiscountBook: 0,
    userDiscounts: null,
    discount: {
      min: 0,
      max: 0,
    },
    manager: {
      loading: false,
      discount: 0,
      status: null,
      message: "",
      sent: 0,
      description: "",
    },
    sessionId: null,
    intervalId: null,
  }),
  created() {
    this.user = JSON.parse(localStorage.getItem("userData"));

    // Inicializa a conexão do socket no hook 'created'
    this.socket = io(`${process.env.VUE_APP_API_URL}`);

    // Listener para eventos de conexão
    this.socket.on('connect', () => {
      this.socketStatus = 'conectado';
      console.log('Conectado ao servidor Socket.io');
    });

    // Listener para eventos de desconexão
    this.socket.on('disconnect', () => {
      this.socketStatus = 'desconectado';
      console.log('Desconectado do servidor Socket.io');
    });

    // Listener para eventos personalizados do servidor
    this.socket.on('message', (data) => {
      console.log('Mensagem recebida do servidor:', data);
    });
  },
  mounted() {
    this.$root.$on("open:modal-import", () => {
      this.$bvModal.show("modalImport");
    });

    this.socket.addEventListener("manager-discount:action", (event) => {
      this.manager.status = event.status
    });

    this.sessionId = this.generateUniqueNumber();

    this.fetchUserDiscounts();

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.getData();
    });

    this.$root.$on("input");

    this.filters.name = this.$route.query.name ?? ''
    this.filters.email = this.$route.query.email ?? ''
    this.filters.cpf = this.$route.query.cpf ?? ''
    this.filters.cellphone = this.$route.query.cellphone ?? ''

    // Carregar opções assíncronas em paralelo
    this.loadOptions();

    if (this.hasFilters()) {
      this.getData();
    }
  },
  beforeDestroy() {
    this.stopFetching();

    if (this.socket) {
      this.socket.disconnect();
    }

    this.$root.$off("paginate:update");
    this.$root.$off("open:sidebar");
  },
  watch: {
    "formEnrollmentEAD.products": function () {
      this.formEnrollmentEAD.subtotal = this.formEnrollmentEAD.products.reduce((sum, product) => sum + product.value, 0);
      
      // Aplica o desconto no subtotal para obter o total
      this.formEnrollmentEAD.total = this.applyDiscountOnValue(
        this.formEnrollmentEAD.subtotal, 
        this.formEnrollmentEAD.discount?.percent ?? 0
      );

      this.generateInstallments();
    },
    "manager.status": function (newValue, oldValue) {
      if (newValue === 1 || newValue === 2) {
        this.stopFetching()

        if (newValue === 1) {          
          const discount = this.formRequestDiscount;
          
          this.formEnrollmentEAD.discount = {
            percent: discount.percent,
            total: discount.total,
            note: discount.note,
          }

          this.generateInstallments();

          if (discount.percent) {
            this.formEnrollmentEAD.total = this.applyDiscountOnValue(this.formEnrollmentEAD.subtotal, discount.percent);
          }

          this.notify(
            "Desconto aplicado com sucesso.",
            "CheckIcon",
            "success",
            null,
            10000
          );
        }
      }
    },
  },
  methods: {
    generateUniqueNumber() {
      const timestamp = Date.now()
      const randomNum = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000
      
      return `${this.user.id}-${timestamp}-${randomNum}`
    },
    generateInstallments() {
      const installments = [];
      const maxInstallments = 12;
      
      let total = this.formEnrollmentEAD.products.reduce((total, product) => total + product.value, 0);

      let discountPercent = this.formEnrollmentEAD.discount.percent || 0;
      let discountAmount = 0; // Valor do desconto

      // Armazena a parcela selecionada antes de regenerar (se houver)
      const selectedInstallment = this.formEnrollmentEAD.installment ? { ...this.formEnrollmentEAD.installment } : null;

      
      // Aplica o desconto, se houver
      if (discountPercent > 0) {
        discountAmount = (discountPercent / 100) * total; // Calcula o valor do desconto
        total = total - discountAmount; // Total com desconto aplicado
      }

      for (let i = 1; i <= maxInstallments; i++) {
        const installmentValue = total / i; // Valor de cada parcela com base no total com desconto
        const formattedValue = installmentValue.toFixed(2); // Formata com 2 casas decimais
        
        // Monta o título com ou sem o desconto
        const title = discountPercent > 0 
          ? `${i}x de R$ ${this.toCurrency(parseFloat(formattedValue))} = ${this.toCurrency(total)} sem juros (-${this.toCurrency(discountAmount)})`
          : `${i}x de R$ ${this.toCurrency(parseFloat(formattedValue))} = ${this.toCurrency(total)} sem juros`;

        const installmentOption = {
          title: title,
          installments: i,
          value: parseFloat(formattedValue),
          total: total, // Total com desconto
          discountPercentage: discountPercent, // Percentual de desconto aplicado
          discountTotal: discountAmount, // Valor do desconto
        };

        installments.push(installmentOption);

        // Atualiza a parcela selecionada, se corresponder ao número de parcelas
        if (selectedInstallment && selectedInstallment.installments === i) {
          this.formEnrollmentEAD.installment = { ...installmentOption };
        }
      }

      this.optionsInstallments = installments;
    },
    closeModalDiscountRequest() {
      this.formRequestDiscount.percent = 0;
      this.formRequestDiscount.note = '';

      this.$bvModal.hide("modal-discount-request");
    },
    async closeModalDiscountOfUser() {
      this.formGiveDiscount.percent = 0;
      this.formGiveDiscount.note = '';

      this.$bvModal.hide("modal-discount-apply");
    },
    maxRequestDiscount(value) {
      return value >= 100 ? 100 : value
    },
    maxDiscount() {
      this.formGiveDiscount.percent = this.formGiveDiscount.percent <= this.userDiscountEAD.max ? this.formGiveDiscount.percent : this.userDiscountEAD.max;
    },
    async fetchUserDiscounts() {
      this.userDiscounts = await this.$store.dispatch("User/showDiscounts")

      this.userDiscountEAD = this.userDiscounts.find(item => item.name === "Curso Online");
      this.userDiscountBook = this.userDiscounts.find(item => item.name === "Apostila");
    },
    submitGiveDiscount() {
      this.$v.formGiveDiscount.$touch();
      
      if (!this.$v.formGiveDiscount.$error) {
        this.$bvModal.hide('modal-discount-apply');
        
        this.formEnrollmentEAD.discount = {
          percent: this.formGiveDiscount.percent,
          total: this.formGiveDiscount.total,
          note: this.formGiveDiscount.note,
        }

        this.generateInstallments()

        if (this.formGiveDiscount.percent) {
          this.formEnrollmentEAD.total = this.applyDiscountOnValue(this.formEnrollmentEAD.subtotal, this.formGiveDiscount.percent);
        }

        this.notifyDefault("success", "Desconto aplicado com sucesso.");
      }
    },
    applyDiscountOnValue(value, discount) {
      const discountAmount = (discount / 100) * value;
      return value - discountAmount;
    },
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(value);
    },
    submitGetDiscount() {
      this.manager.loading = true;
      this.manager.sent = 0;
      this.manager.status = 0;
      this.$v.formRequestDiscount.$touch();

      if (!this.$v.formRequestDiscount.$error) {     
        const discount = this.formRequestDiscount;

        const data = {
          discount: discount,
          customer: this.formEnrollmentEAD.customer,
          origin: this.formEnrollmentEAD.origin,
          unit: this.formEnrollmentEAD.unit,
          method: this.formEnrollmentEAD.method,
          installment: this.formEnrollmentEAD.installment,
          products: this.formEnrollmentEAD.products,
          sessionId: this.sessionId,
          total: this.formEnrollmentEAD.total,
          subtotal: this.formEnrollmentEAD.subtotal,
        };

        this.$store
          .dispatch("Order/requestDiscountProducts", {
            uuid: this.$route.params.uuid,
            data: data,
          })
          .then(() => {
            this.manager.sent = 1;
            this.startFetching()
            this.$bvModal.hide("modal-discount-request");
          })
          .finally(() => {
            this.manager.loading = false;
          });
      }
    },
    stopFetching() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
    },
    startFetching() {
      this.intervalId = setInterval(this.fetchRequestDiscount, 3000)
    },
    async fetchRequestDiscount() {
      this.$store
        .dispatch("Order/requestDiscountStatus", this.sessionId)
        .then((res) => {
          const hasStatusOne = res.some(item => item.status === 1);

          if (hasStatusOne) {
            this.manager.status = 1;
          } else {
            this.manager.status = 0;
          }
        })
    },
    getDiscount() {
      this.$bvModal.show("modal-discount-request");
    },
    // Método para carregar opções assíncronas
    async loadOptions() {
      try {
        const [origins, paymentMethods, CardNetworks] = await Promise.all([
          this.$store.dispatch('Order/forSelectOrigins'),
          this.$store.dispatch('Order/paymentMethods'),
          this.$store.dispatch("CardNetwork/forSelect"),
        ]);
        this.optionsCardNetworks = CardNetworks;
        this.optionsOrigins = origins;
        this.optionsPaymentMethods = paymentMethods;
        
        this.optionsPaymentMethodsEAD = this.optionsPaymentMethods.filter((item) => {
          return item.title !== 'Carta de Crédito' && item.title !== 'Cortesia' && item.title !== 'Migração';
        });
      } catch (error) {
        console.error('Erro ao carregar opções:', error);
        // Aqui você pode adicionar tratamento de erro, como mostrar uma notificação
      }
    },
    getIconPaymentMethod(code) {
      switch (code) {
        case "card_credit":
          return "bi-credit-card-2-back";
        case "card_debit":
          return "bi-credit-card-2-front";
        case "billet":
          return "bi-upc-scan";
        case "letter":
          return "bi-file-earmark-medical";
        case "pix":
          return "bi-qr-code-scan";
        case "migracao":
          return "bi-arrow-down-up";
        case "cortesia":
          return "bi-gift";
        case "unit":
          return "bi-office";
        case "money":
          return "bi-cash-stack";
        case "check":
          return "bi-card-text";
        default:
          return "";
      }
    },
    hasFilters() {
      return (
        this.filters.name ||
        this.filters.email ||
        this.filters.cpf ||
        this.filters.cellphone
      );
    },
    classroomSelected(e) {
      this.grid.date_end = e.date_end
    },
    clearGridForm() {
      this.grid = {
        note: "",
        product: "",
        date_end: "",
        classroom: "",
      };
    },
    clearNewOrder() {
      this.order = {
        note: "",
        products: [],
      };
    },
    clearNewOrderEAD() {
      this.formEnrollmentEAD = {
        customer: null,
        products: [],
        origin: '',
        note: '',
        method: '',
        brand: '',
        subtotal: 0,
        total: 0,
        transaction: '',
        discount: {
          percent: 0,
          total: 0,
          note: 0,
        },
        installment: null
      };
    },
    btnCustomerAddProductAction(customerUuid) {
      this.customerUuid = customerUuid;
      this.$bvModal.show("add-courtesy");
    },
    btnCustomerAddProductEADAction(customerUuid) {
      this.customerUuid = customerUuid;
      this.formEnrollmentEAD.customer = customerUuid;
      this.$bvModal.show("modal-enrollment-ead");
    },
    btnCustomerAddCartAction(customerUuid) {
      this.customerUuid = customerUuid;
      this.isBtnCustomerAddCartActive ? false : true;
      this.$root.$emit("open:modal-customer-add-cart", {
        status: this.isBtnCustomerAddCartActive,
      });
    },
    btnCustomerAddGridAction(uuid) {
      this.customerUuid = uuid;
      this.$bvModal.show("add-grid");
    },
    btnCustomerLoginAction(uuid) {
      const url = `https://${this.user.school.domain}/entrar/${uuid}`;
      window.open(url, "_blank");
    },
    submitNewGrid() {
      this.$v.grid.$touch();
      this.submitted = true;

      if (!this.$v.grid.$error) {
        this.$store
          .dispatch("Customer/newOrderGrid", {
            uuid: this.customerUuid,
            data: {
              product: this.grid.product?.code,
              date_end: this.grid.date_end,
              classroom: this.grid.classroom?.code,
              note: this.grid.note,
            }
          })
          .then(() => {
            this.clearGridForm();
            this.$v.grid.$reset;
            this.notifyDefault("success");
            this.$bvModal.hide("add-grid");
          })
          .catch(() => {
            this.submitted = false;
            this.customerUuid = "";
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submitted = false;
          });
      } else {
        this.submitted = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    submitNewOrder() {
      this.$v.formCourtesy.$touch();
     
      if (!this.$v.formCourtesy.$error) {
        this.submitted = true;

        const dataForm = {
          ...this.formCourtesy,
          total: 0,
          subtotal: 0,
          origin: this.formCourtesy.origin.code,
        }

        this.$store
          .dispatch("Customer/newOrder", {
            uuid: this.customerUuid,
            data: dataForm,
          })
          .then((res) => {
            this.clearNewOrder();
            this.$v.formCourtesy.$reset;
            this.notifyDefault("success");
            this.$router.push({
              name: "transactions-orders-preview",
              params: { uuid: res.uuid },
            });
          })
          .catch(() => {
            this.submitted = false;
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submitted = false;
          });
      } else {
        this.submitted = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    submitNewOrderEAD() {
      this.$v.formEnrollmentEAD.$touch();
      
      if (!this.$v.formEnrollmentEAD.$error) {
        this.submitted = true;

        const dataForm = {
          ...this.formEnrollmentEAD,
          brand: this.formEnrollmentEAD.brand.code,
          method: this.formEnrollmentEAD.method.code,
          origin: this.formEnrollmentEAD.origin.code,
        }

        this.$store
          .dispatch("Customer/newOrder", {
            uuid: this.customerUuid,
            data: dataForm,
          })
          .then((res) => {
            this.clearNewOrderEAD();
            this.$v.formEnrollmentEAD.$reset();
            this.notifyDefault("success");
            this.$router.push({
              name: "transactions-orders-preview",
              params: { uuid: res.uuid },
            });
          })
          .catch(() => {
            this.submitted = false;
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submitted = false;
          });
      } else {
        this.submitted = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    async fetchProducts(term) {
      this.optionsProducts = [];

      if (term.length > 2) {
        this.$store
          .dispatch("Product/searchOfCategories", {
            term: term,
            categories: [2, 4, 5, 6, 7],
          })
          .then((res) => {
            this.optionsProducts = res.data;
          });
      }
    },
    async fetchProductsSale(term) {
      this.optionsProducts = [];

      if (term.length > 2) {
        this.$store
          .dispatch("Product/searchOfCategories", {
            term: term,
            categories: [2, 6],
          })
          .then((res) => {
            this.optionsProducts = res.data;
          });
      }
    },
    async fetchProductsGrid(term) {
      this.optionsProductsGrid = [];

      if (term.length > 2) {
        this.$store
          .dispatch("Product/searchOfCategories", {
            term: term,
            categories: [3],
          })
          .then((res) => {
            this.optionsProductsGrid = res.data;
          });
      }
    },
    productSelected(event) {
      if (event) {
        let checkProduct = false;

        this.formCourtesy.products.forEach((element) => {
          if (element.code === event.code) {
            checkProduct = true;
          }
        });

        if (!checkProduct) {
          this.formCourtesy.validity = event.validity
          this.formCourtesy.products.push(event);
        }
      }

      this.product = "";
    },
    productEADSelected(event) {
      if (event) {
        let checkProduct = false;

        this.formEnrollmentEAD.products.forEach((element) => {
          if (element.code === event.code) {
            checkProduct = true;
          }
        });

        if (!checkProduct) {
          this.formEnrollmentEAD.validity = event.validity
          this.formEnrollmentEAD.products.push(event);
        }
      }

      this.product = "";
    },
    productsGridSelected(event) {
      this.optionsClassrooms = [];

      if (event) {
        this.optionsClassrooms = event.classrooms;
      }
    },
    search() {
      this.btnSearch = true;
      this.getData();
    },

    handleFileUpload() {
      this.toImport.file = this.$refs.file.files[0];
    },

    submitImport() {
      this.submitted = true;

      const formData = new FormData();
      formData.append("file", this.toImport.file);
      formData.append("send_mail", this.toImport.send_mail);

      this.$v.toImport.$touch();

      if (!this.$v.toImport.$error) {
        this.$store
          .dispatch("Customer/import", formData)
          .then(() => {
            this.notifyDefault("success");
            this.getData();
            this.$bvModal.hide("modalImport");
          })
          .finally(() => {
            this.submitted = false;
          });
      } else {
        this.submitted = false;
        this.notifyDefault(
          "error",
          "Por algum motivo, não conseguimos importar. Verifique se o arquivo é XLSX e sua estrutura."
        );
      }
    },
    updatePage() {
      this.getData();
    },

    async getData() {
      this.loading = true;
      this.dataItems = [];

      this.$store
        .dispatch("Customer/all", this.filters)
        .then((res) => {
          this.dataItems = res.data.data;
          this.rowsTable = res.data.data.length;
          this.totalRows = res.data.meta.total;
          this.filters.currentPage = res.data.meta.current_page;

          this.$router.push(
            {
              name: 'customers-list',
              query: {
                cpf: this.filters.cpf,
                email: this.filters.email,
                cellphone: this.filters.cellphone,
                name: this.filters.name
              }
            }
          );
        })
        .finally(() => {
          this.btnSearch = false;
          this.loading = false;
        });
    },

    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar caso não tenha nenhum cadastro vinculado.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Customer/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },

    removeProduct(idx, mode) {
      if (!mode) {
        this.formCourtesy.products.splice(idx, 1);
      } else {
        this.formEnrollmentEAD.products.splice(idx, 1);
     
        this.formEnrollmentEAD.total = 0;
        this.formEnrollmentEAD.products.forEach((element) => {
          this.formEnrollmentEAD.total = this.formEnrollmentEAD.total + element.value;
        })
      }
    },
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.text-elipse-400 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}

.dropdown-actions {
  >ul.dropdown-menu-right {
    transform: none !important;
    right: 0px !important;
    left: auto !important;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
